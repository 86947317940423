export const roles = {
    ADMINISTRATOR: 'ADMINISTRATOR',
    ORGANIZATION_ADMINISTRATOR: 'ORGANIZATION_ADMINISTRATOR',
    QUALITY_CONTROL: 'QUALITY_CONTROL',
    CLINIC_ADMINISTRATOR: 'CLINIC_ADMINISTRATOR',
    CLINIC_LAB: 'CLINIC_LAB',
    CLINIC_USER: 'CLINIC_USER',
    CLINIC_USER_FULL_INSIGHT: 'CLINIC_USER_FULL_INSIGHT',
    API_USER: 'API_USER',
    C4T_USER: 'C4T_USER',
    INTEGRATION_USER: 'INTEGRATION_USER'
};

export const roleWeights = {
    [roles.ADMINISTRATOR]: 100,
    [roles.ORGANIZATION_ADMINISTRATOR]: 90,
    [roles.QUALITY_CONTROL]: 80,
    [roles.CLINIC_ADMINISTRATOR]: 70,
    [roles.CLINIC_LAB]: 60,
    [roles.CLINIC_USER_FULL_INSIGHT]: 50,
    [roles.CLINIC_USER]: 40,
    [roles.API_USER]: 30,
    [roles.C4T_USER]: 20,
    [roles.INTEGRATION_USER]: 10
};

export const hasRequiredRole = (userRoles, requiredRole) => {
    if (!userRoles || !requiredRole) return false;
    const requiredRoleWeight = roleWeights[requiredRole];
    return userRoles.every(role => roleWeights[role] <= requiredRoleWeight);
};