export default {
    labels: {
        log: {
            login: 'Zaloguj się',
            log_out: 'Wyloguj się',
        },
        landing_page: {
            login: 'Zaloguj się',
            register_clinic: 'Zarejestruj nową klinikę',
            forget_password: 'Zapomniałem hasła',
            see_api_docs: 'Zobacz dokumentacje Api',
        },
        required: 'Wymagane pole',
        delete_user: 'Usuń Użytkownika',
        employee: 'Pracownik',
        delete_success: 'został usunięty',
        empty_result: 'Brak wyników dla podanych kryteriów',
        page_not_found: 'Nie znaleziono strony',
        welcome_message: 'Witaj w portalu CalproSmart.',
        roles: {
            ADMINISTRATOR: 'ADMINISTRATOR',
            ORGANIZATION_ADMINISTRATOR: 'ORGANIZATION ADMINISTRATOR',
            QUALITY_CONTROL: 'QUALITY CONTROL',
            CLINIC_ADMINISTRATOR: 'CLINIC ADMINISTRATOR',
            CLINIC_LAB: 'CLINIC LAB',
            CLINIC_USER: 'CLINIC USER',
            CLINIC_USER_FULL_INSIGHT: 'CLINIC USER - FULL INSIGHT',
            API_USER: 'API USER',
        },
        select_field: {
            language: 'Język',
            clinic: 'Klinika',
            country: 'Kraj',
            physician: 'Lekarz prowadzący',
            frequency_type: 'Typ częstości',
            organization: 'Organizacja'
        },
        buttons: {
            create: 'Utwórz',
            cancel: 'Anuluj',
            next: 'Next',
            back: 'Back',
            finish: 'Finish',
            home: 'Back to home page',
            search: 'Wyszukaj',
            edit: 'Edytuj',
            details: 'Szczegóły',
            export_results: 'Eksport wyników do CSV',
            export_filtered_results: 'Eksport wyników do excela',
            export_filtered_patients: 'Eksport pacjentów do excela',
            update: 'Aktualizuj',
            delete: 'Usuń',
            close: 'Zamknij',
            send: 'Wyślij',
            reset_password: 'Zresetuj hasło',
            save: 'Zapisz',
            accept: 'Akceptuj',
            reject: 'Odrzuć',
            confirm: 'Potwierdź',
            reset_mfa: 'Zresetuj MFA dla użytkownika',
            export_all_results_to_pdf: 'Eksport wszystkich wyników do PDF',
            export_this_result_to_pdf: 'Eksport tego wyniku do PDF'
        },
        fields: {
            search: 'Wyszukaj',
            clinic: 'Klinika',
            status: 'Status',
            role: 'Rola',
            language: 'Język'
        },
        table: {
            empty_result: 'Brak wyników dla podanych kryteriów',
            error_message: 'Wystąpił błąd, nie można wyświetlić wyników'
        },
        statuses: {
            active: 'Aktywny',
            inactive: 'Nieaktywny',
            created: 'Utworzony'
        },
        menu_labels: {
            clinics: 'Kliniki',
            organizations: 'Organizacje',
            staff_users: 'Pracownicy',
            patients: 'Pacjenci',
            manage_patients: 'Zarządzaj pacjentami',
            results: 'Wyniki',
            pending_results: 'Oczekujące wyniki',
            batches: 'Partie',
            documents: 'Dokumenty',
            faq: 'Zarządzanie FAQ',
            support: 'Wsparcie',
        },
        reset_password: {
            title: 'Reset hasła',
            message: 'Straciłeś pamięć hasła? Podaj adres e-poczty a prześlemy ci kolejne polecenia.',
            success_title: 'Pomyślność',
            error_message: 'Serwer przeżywa problemy wewnętrzne. Proszę o kontakt administratora.',
            success: 'Jeżeli namiary są poprawne, to do twojej e-skrzynki trafiło łącze odnowy hasła.\nProszę zobaczyć.',
        },
        managePatients_labels: {
            create_patient_button: 'Stwórz pacjenta',
            page_title: 'Zarządzaj pacjentami',
            patient_type: 'Typ pacjenta',
            patient_types: {
                home: 'Pacjent na monitoring domowy',
                lab: 'Pacjent laboratoryjny'
            },
            columns: {
                id: 'Calpro Id',
                externalId: 'Id',
                email: 'Email',
                firstName: 'Imię',
                lastName: 'Nazwisko',
                treatmentType: 'Typ pacjenta',
                created: 'Utworzony',
                active: 'Status',
                clinicName: 'Klinika',
                details: '',
            }
        },
        manageClinics_labels: {
            create_clinic_button: 'Stwórz klinikę',
            page_title: 'Zarządzaj klinikami',
            columns: {
                name: 'Nazwa',
                city: 'Miejscowość',
                countryCode: 'Kraj',
                status: 'Status',
                organizationName: 'Organizacja',
                created: 'Utworzony',
                details: '',
            },
        },
        manageOrganizations_labels: {
            create_organization_button: 'Stwórz organizację',
            page_title: 'Zarządzaj organizacjami',
            columns: {
                name: 'Nazwa',
                moderateThreshold: 'Moderate threshold',
                severeThreshold: 'Severe threshold',
                active: 'Status',
            },
        },
        manageBatches_labels: {
            create_batch_button: 'Stwórz partię',
            page_title: 'Zarządzaj partiami',
            releaseStatus: 'Status',
            columns: {
                number: 'Nr partii',
                expirationDate: 'Data ważności',
                creationDate: 'Utworzony',
                status: 'Status',
                details: '',
            },
        },
        manageResults_labels: {
            page_title: 'Zarządzaj wynikami',
            classification: 'Klasyfikacja',
            result_type: 'ResultType',
            pendingResultLabel: 'Oczekujące Wyniki',
            pendingResultTooltip: '"Zaakceptowane" są to zaakceptowane Oczekujące wyniki "Odrzucone" są odrzuconymi wynikami',
            pendingResultTypes: {
                accepted: 'Zaakceptowane',
                rejected: 'Odrzucone'
            },
            result_types: {
                home: 'Home',
                lab: 'Lab'
            },
            columns: {
                date: 'Data',
                externalPatientId: 'Id Pacjenta',
                patientInfo: 'Pacjent',
                clinicName: 'Klinika',
                batchNumber: 'Partia',
                finalConcentration: 'Koncentracja',
                overallResult: 'Klasyfikacja',
                archived: 'Zarchiwizowane',
                labResult: 'Typ wyniku',
                details: ''
            },
        },
        signup_labels: {
            user_tab: 'Dane osobowe',
            clinic_tab: 'Informacje o klinice',
            summary_tab: 'Podsumowanie',
            first_name: 'Imię',
            last_name: 'Nazwisko',
            email: 'Email',
            password: 'Hasło',
            confirm_password: 'Powtórz hasło',
            company: 'Firma',
            address: 'Adres',
            city: 'Miejscowość',
            country: 'Kraj',
            contact_name: 'Osoba do kontaktu',
            contact_email: 'Email konatktowy',
            contact_tel: 'Telefon kontaktowy',
            captcha: 'captcha',
            provide_contact_info: 'Osoba kontaktowa dla kliniki',
            provide_contact_info_subtitle: 'Ta osoba będzie administratorem kliniki.',
            provide_clinic_info: 'Podaj dane kliniki',
            confirm_info: 'Przejżyj i potwierdź podane dane',
            done: 'Dziękujemy! Proces rejestracji został ukończony.'
        },
        patient_details: {
            page_title: 'Szczegóły Pacjenta',
            first_name: 'Imię',
            last_name: 'Nazwisko',
            address1: 'Adres linia 1',
            address2: 'Adres linia 2',
            postcode: 'Kod pocztowy',
            city: 'Miasto',
            country: 'Kraj',
            email: 'Adres e-mail',
            phone1: 'Numer telefonu komórkowego',
            phone2: 'Numer zastępczego telefonu komórkowego',
            patient_id: 'ID pacjenta',
            sex: 'Płeć',
            date_of_birth: 'Data urodzenia',
            clinic: 'Klinika',
            physician: 'Lekarz prowadzący',
            frequency: 'Częstość',
            frequency_type: 'Typ częstości',
            language: 'Język',
            home_monitoring: 'Pacjenty aktywny na monitoring domowy',
            interval_day: 'dzień/dni',
            interval_week: 'tydzień/tygodni',
            interval_month: 'miesiąc/miesięcy',
            interval_year: 'rok/lat',
            moderate_threshold: 'Umiarkowany threshold',
            high_threshold: 'Wysoki threshold',
            sex_male: 'Mężczyzna',
            sex_female: 'Kobieta',
            thirdPartyConsent: 'Zgoda na przetwarzanie danych przez podmioty współpracujące',
            mfa: 'Uwierzytelnienie wieloetapowe',
            is_active: 'Aktywny',
            patientCreated: 'Pacjent stworzony',
            patientUpdated: 'Pacjent zaktualizowany',
        },
        staff_users: {
            page_title: 'Zarządzaj użytkownikami załogi',
            id: 'Calpro Id',
            email: 'Email',
            firstName: 'Pierwsze name',
            lastName: 'Ostatnie name',
            active: 'Aktywny',
            edit: 'Edituj',
            details: 'Szczegóły',
            primaryPhone: 'Pierszy phone',
            secondaryPhone: 'Drugi phone',
            roles: 'Rola',
            clinicName: 'Przychodnia',
            create: 'Stwórz pracownika'
        },
        user_details: {
            page_title: 'Szczegóły Użytkownika',
            first_name: 'Imię',
            last_name: 'Nazwisko',
            address1: 'Adres linia 1',
            address2: 'Adres linia 2',
            postcode: 'Kod pocztowy',
            city: 'Miasto',
            email: 'Adres e-mail',
            phone1: 'Numer telefonu komórkowego',
            phone2: 'Numer zastępczego telefonu komórkowego',
            mfa: 'Uwierzytelnienie wieloetapowe',
            language: 'Język',
            clinic: 'Klinika',
            is_active: 'Czy aktywny',
            calpro_admin: 'Calpro_Admin',
            calpro_qc: 'IsCalpro_Qc',
            clinic_admin: 'Clinic_Admin',
            clinic_lab: 'Clinic_Lab',
            clinic_user: 'Clinic_User',
            clinic_user_all_results: 'Clinic_User_All_Results',
            roles: 'Role',
            no_roles: 'Nie wybrano żadnej roli, wybierz przynajmniej jedną',
            success: 'Pracownik został utworzony z pomyślnością',
            employeeCreated: 'Pracownik stworzony',
            employeeUpdated: 'Pracownik zaktualizowany',
            warning_title: 'Warning',
            deactivation_warning: 'You have deactivated a staff user. If they had any patients, remember to reallocate them!',
        },
        clinic_details: {
            page_title: 'Kreuj albo przemień przychodnię',
            name: 'Nazwa przychodni',
            address1: 'Adres linia 1',
            address2: 'Adres linia 2',
            postcode: 'Kod pocztowy',
            city: 'Miasto',
            country: 'Kraj',
            contactName: 'Nazwisko kontaktu',
            contactTel: 'Telefon kontaktowy',
            contactEmail: 'Email do kontaktu',
            status: 'Status',
            update_ok: 'Klinika została uaktualniona zgodnie z poprawnością',
            clinicCreated: 'Gratuluję! Udało Ci się wytworzyć klinikę poprawnie! Odwiedź zakładkę Użytkownicy aby dodać administratora przychodni.',
            clinicUpdated: 'Klinika zaktualizowana',
            mfa_patient: 'Uwierzytelnienie wieloetapowe pacjenta',
            mfa_staff: 'Uwierzytelnienie wieloetapowe pracownika',
            hide_results_patients: 'Ukryj wyniki dla pacjentów',
            hide_results_clinic_users: 'Ukryj wyniki dla pracowników kliniki',
        },
        organization_details: {
            page_title: 'Szczegóły organizacji',
            name: 'Nazwa',
            logo: 'Logo',
            moderateThreshold: 'Moderate threshold',
            severeThreshold: 'Severe threshold',
            thirdPartyConsentRequired: 'Wymagaj akceptacji zgody na pretwarzanie od pacjentów',
            fullPatientInformationRequired: 'Wymagaj wszystkich informacji o pacjentach',
            phoneRegexp: 'Regexp dla numerów telefonu',
            organizationCreated: 'Organizacja stworzona',
            organizationUpdated: 'Organizacja zaktualizowana',
            active: 'Aknywna',
            deactivate_organization: {
                title: 'Deaktywacja kliniki',
                message: "Wybierając 'Potwierdź',deaktywujesz wszystkie kliniki, użytkowników i pacjentów przypisanych do tej organizacji. Jeżeli nie chcesz deaktywować którejś z klinik musisz przypisać ją do aktywnej organizacji przed deaktywacją tej organizacji.",
                cancel: 'Anuluj',
                confirm: 'Potwierdź'
            }
        },
        result_basic_view: {
            concentration: 'Koncentracja',
            classification: 'Klasyfikacja',
            patient: 'Pacjent',
            patientId: 'Id pacjenta',
            date: 'Data'
        },
        result_view: {
            result: 'Wynik',
            result_details: 'Szczegóły wyniku',
        },
        create_batch: {
            create_batch: 'Stwórz partie',
            batch_details: 'Szczegóły partii ',
            batch_number: 'Numer partii',
            manufacture_date: 'Data produkcji',
            expiry_date: 'Data ważności',
            moderate_threshold: 'Umiarkowany próg',
            severe_threshold: 'Poważny próg',
            created_by: 'Stworzone przez',
            release_status: 'Status wypuszczenia',
            barcode: 'Barcode',
            calibration_equation: 'Parametry równania kalibracji',
            calibration_parameter_a: 'Parametr kalibracji A',
            calibration_parameter_b: 'Parametr kalibracji B',
            calibration_parameter_c: 'Parametr kalibracji C',
            qc_review: 'QC review',
            qc_approved: 'QC approved',
            released: 'Wypuszczony',
            reject_batch: 'Odrzucona partia',
            reject_batch_line1: 'To spowodouje odrzucenia partii i poinformowanie Calpro QC.',
            reject_batch_line2: 'Przed odrzuceniem partii musisz napisać powód',
            reject_batch_line3: 'Jeśli nie chcesz kontynuować, kliknij na \'anuluj\' albo na \'akceptuj\' aby odrzucić partie',
            reason_label: 'Powody odrzucenia',
            reject: 'Odrzuć',
            batch_created: 'Partia utworzona',
            batch_updated: 'Partia zaktualizowana',
            batch_failed: 'Operacja na partii nieudana',
        },
        detailed_result_view: {
            phoneBrand: 'Firma telefonu',
            phoneModel: 'Model telefonu',
            phoneName: 'Nazwa telefonu',
            appVersion: 'Wersja aplikacji',
            readerVersion: 'wersja readera',
            batchNumber: 'Numer partii',
            batchExpDate: 'Data przydatności partii',
            cScore: 'C Score',
            tScore: 'T Score',
            tcRatio: 'T/C Ratio',
            cPeakPosition: 'C peak position',
            tPeakPosition: 'T peak position',
            cThresh: 'C Threshold',
            notes: 'Notatki',
            image: 'Obraz',
            stripImage: 'Obraz wycięty',
            updateResultNotes: 'Aktualizuj notatki o wyniku',
            notesCharacterLimit: 'Limit 255 znaków',
            notesUpdatedSuccessfully: 'Notatka dodana',
            notesUpdateFail: 'Nie dodano notatki',
        },
        batchResults_labels: {
            sampling_page_title: 'Lista wyników partii',
            review_page_title: 'Needs Translation',
            columns: {
                resultId: 'ID',
                date: 'Data',
                sampleId: 'ID Próbki',
                runNo: 'Numer próby',
                tpeakPosition: 'Pozycja maksymalna T',
                cpeakPosition: 'Pozycja maksymalna X',
                finalScore: 'Wynik końcowy',
                tscore: 'Punktacja T',
                cscore: 'Punktacja C',
                tcRatio: 'Współczynnik TC',
                finalConcentration: 'Koncentracja',
                overallResult: 'Klasyfikacja',
                details: 'Szczegóły'
            },
        },
        active: {
            active: 'Aktywny',
            inactive: 'Nieaktywny'
        },
        myPage_labels: {
            page_title: 'Moja strona',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            email: 'Email',
            clinicName: 'Klinika',
            roles: 'Role',
            view_agreements: 'Pokaż podpisane zgody',
            apiVersion: 'Wersja API',
            releaseNotes: 'Informacje o wersjach'
        },
        documents_section: {
            documents: {
                publish: "Opublikuj versje",
                cannot_publish: "Wszystkie tłumaczenia muszą być ukończone.",
            },
            versions: {
                title: 'Wersje',
                add_tooltip: "Dodaj nową wersje",
                release_date: "Data publikacji",
                creation_date: 'Data utworzenia'
            },
            translations: {
                title: 'Tłumaczenia',
                add_tooltip: 'Dodaj nowe tłumaczenie',
            },
            document_representation: {
                edit: 'Edytuj dokument'
            },
            edit_document_representation: {
                finish: 'Zakończony',
                in_progress: 'Zapisz jako niedokończony',
                cancel: 'anuluj',
            },
            add_version: {
                title: 'Dodaj nową wersje',
                sub_label: 'Nazwa wersji',
                save: 'Zapisz',
                cancel: 'anuluj',
                success: 'Dodano nową wersje'
            },
            add_translation: {
                title: 'Dodaj nowe tłumaczenie',
                sub_label: 'Nazwa jęzka',
                save: 'Zapisz',
                cancel: 'anuluj',
                success: 'Dodano nowe tłumaczenie'
            },
            tooltips: {
                none: 'Identyczne z poprzednią wersją',
                finish: 'Tłumaczenie skończone, gotowe na publikacje',
                in_progress: 'W trakcie zmian',
            },
            publish: {
                title: 'Opublikuj nową wersję',
                notify: 'Powiadom użytkowników o nowej wersji?'
            },
        },
        support: {
            page_title: 'Wsparcie',
            faq: 'FAQ',
            email: 'Wsparcie email',
        },
        support_faq: {
            page_title: 'FAQ',
        },
        faq: {
            page_title: 'Zarządzanie FAQ',
            edit: 'Edytuj',
            publish: 'Opublikuj',
            addNew: 'Dodaj',
            selectOptions: {
                PATIENT: 'Pacjent',
                EMPLOYEE: 'Pracownik',
            },
        },
        faq_item: {
            show_more: 'Show more',
        },
        add_edit_faq: {
            required_translation: 'Angielskie tłumaczenie jest wymagane',
            lack_of_answers: 'Część pytań nie zostanie zapisana z powodu braku odpowiedzi',
            add_title: 'Dodaj pytanie i odpowiedź',
            edit_title: 'Edytuj pytanie i odpowiedź',
            save: 'Zapisz',
            delete: 'Usuń',
            question: {
                placeholder: 'Wpisz pytanie...',
            },
            answer: {
                placeholder: 'Wpisz odpowiedź...',
            },
            photo: {
                add: 'Dodaj obraz',
                added_suffix: 'został dodany jako załącznik.',
            },
            video: {
                label: 'Link do wideo (opcjonalny):',
                placeholder: 'Wklej link do wideo',
            },
            language: {
                EN: 'Angielski',
                AR: 'Arabski',
                CA: 'Kataloński',
                CS: 'Czeski',
                DE: 'Niemiecki',
                EL: 'Grecki',
                ES: 'Hiszpański',
                FI: 'Fiński',
                HE: 'Hebrajski',
                HU: 'Węgierski',
                IS: 'Islandzki',
                IT: 'Włoski',
                KO: 'Koreański',
                NL: 'Holenderski',
                SK: 'Słowacki',
                SV: 'Szwedzki',
                NB: 'Norweski',
                DA: 'Duński',
                PL: 'Polski',
                FR: 'Francuski',
                PT: 'Portugalski',
                SL: 'Słoweński',
            }
        }
    }
}
