import React from 'react'
import {Route} from 'react-router-dom'
import ManagePatients from "../Patients/ManagePatients";
import MyPage from "../MyPage/MyPage";
import ManageClinics from "../Clinics/ManageClinics";
import Documents from "../Documents/Documents";
import ManageBatches from "../Batches/ManageBatches";
import SignAgreement from "../Agreements/SignAgreement";
import ManagePatientResults from "../Results/PatientResult/ManagePatientResults";
import ManagePendingResults from "../Results/PendingResult/ManagePendingResults";
import ManageStaffUsers from "../StaffUsers/ManageStaffUsers";
import {Switch} from "react-router";
import MyAgreements from "../Agreements/MyAgreements";
import ProtectedRoute from "./ProtectedRoute";
import {permission} from "../Auth/permissions";
import PageNotFound from "../common/components/PageNotFound";
import WelcomePage from "../Welcome/WelcomePage";
import ManageOrganizations from "../Organizations/ManageOrganizations";
import {hasRequiredRole, roles} from '../Auth/roles';
import ManageFAQ from '../FAQ/ManageFAQ';
import Support from '../Support/Support';
import SupportFAQ from '../Support/SupportFAQ';

const Routes = () => (

    <React.Fragment>
        <Switch>

            <Route exact path="/my_page" component={MyPage}/>

            <Route exact path="/my_page/agreements" component={MyAgreements}/>

            <Route exact path="/accept_form" component={SignAgreement}/>

            <ProtectedRoute path="/documents/:documentName" component={Documents}
                            allowedPermissions={[
                                permission.documents_add_version,
                                permission.documents_edit,
                                permission.documents_publish]}/>

            <ProtectedRoute path="/organizations/manage_organizations" component={ManageOrganizations}
                            allowedPermissions={[permission.organization_add]}/>

            <ProtectedRoute path="/clinics/manage_clinics" component={ManageClinics}
                            allowedPermissions={[
                                permission.clinic_view_all]}/>

            <ProtectedRoute path="/users/manage_staff_users" component={ManageStaffUsers}
                            allowedPermissions={[
                                permission.employee_view_all,
                                permission.employee_view_clinic]}/>

            <ProtectedRoute path="/patients/manage_patients" component={ManagePatients}
                            allowedPermissions={[
                                permission.patient_view_all,
                                permission.patient_view_clinic,
                                permission.patient_view_your]}/>


            <ProtectedRoute path="/patients/results" component={ManagePatientResults}
                            customPermissionCheck={(user) => {
                                if (hasRequiredRole(user.roles || [], roles.CLINIC_USER)) {
                                    return !user.hideResultsForClinicUsers;
                                }
                                return true;
                            }}
                            allowedPermissions={[
                                permission.patientResult_view_all,
                                permission.patientResult_view_clinic,
                                permission.patientResult_view_your]}/>

            <ProtectedRoute path="/patients/pending_results" component={ManagePendingResults}
                            allowedPermissions={[
                                permission.pendingResults_view_all,
                                permission.pendingResults_view_clinic,
                                permission.pendingResults_view_your]}/>

            <ProtectedRoute
                path="/batches/manage_batches"
                component={ManageBatches}
                allowedPermissions={[permission.batches_view_all]}
            />

            <ProtectedRoute
                path="/faq-management"
                component={ManageFAQ}
                allowedRoles={[roles.ADMINISTRATOR]}
            />

            <ProtectedRoute
                path="/get-support/faq"
                component={SupportFAQ}
            />

            <ProtectedRoute
                path="/get-support"
                component={Support}
            />

            <Route exact path="/" component={WelcomePage}/>

            <Route path="/**" component={PageNotFound}/>

        </Switch>
    </React.Fragment>
);

export default Routes
