import React from 'react';
import PropTypes from "prop-types"
import {connect} from "react-redux";

export class PermissionContainerImplementation extends React.Component {

    static propTypes = {
        allowedPermissions: PropTypes.array,
        exceptPermissions: PropTypes.array,
        allowedRoles: PropTypes.array.isRequired,
        exceptRoles: PropTypes.array,
        onlyForAuthenticated: PropTypes.bool,
        onlyForUnauthenticated: PropTypes.bool,
        children: PropTypes.node.isRequired,
        alternativeComponent: PropTypes.node,
        customPermissionCheck: PropTypes.func,
    };

    static defaultProps = {
        allowedPermissions: [],
        exceptPermissions: [],
        allowedRoles: [],
        exceptRoles: [],
        onlyForAuthenticated: false,
        onlyForUnauthenticated: false,
        alternativeComponent: null,
        customPermissionCheck: null,
    };

    constructor(props) {
        super(props);


        this.setAccessible = this.setAccessible.bind(this);
        this.state = {
            accessible: false
        }

    }

    static checkAllowedPermission(allowedPermission, permissions) {
        return PermissionContainerImplementation.checkPermissions([allowedPermission], [], permissions);
    }


    static checkAllowedPermissions(allowedPermissions, permissions) {
        return PermissionContainerImplementation.checkPermissions(allowedPermissions, [], permissions);
    }

    static checkPermissions(allowedPermissions, exceptPermissions, permissions) {

        if (exceptPermissions.length > 0 && exceptPermissions.some(element => permissions.indexOf(element) > -1)) {
            return false
        }

        if (allowedPermissions.length > 0) {
            return allowedPermissions.some(element => permissions.indexOf(element) > -1)
        }

        return true
    }

    checkRoles(allowedRoles, exceptRoles, roles) {

        if (exceptRoles.length > 0 && exceptRoles.some(element => roles.indexOf(element) > -1)) {
            return false
        }

        if (allowedRoles.length > 0) {
            return allowedRoles.some(element => roles.indexOf(element) > -1);
        }

        return true

    }

    static checkAuthentication(onlyForAuthenticated, onlyForUnauthenticated, isAuthenticated) {

        if (onlyForAuthenticated) {
            return isAuthenticated;
        }
        if (onlyForUnauthenticated) {
            return !isAuthenticated;
        }
        return true
    }

    componentWillMount() {
        this.setAccessible()
    }

    componentDidUpdate() {
        this.setAccessible()
    }


    setAccessible() {

        const {auth, allowedPermissions, exceptPermissions, allowedRoles, exceptRoles, onlyForAuthenticated, onlyForUnauthenticated} = this.props;


        let {isAuthenticated, user} = auth;

        let permissionsCheck = PermissionContainerImplementation.checkPermissions(allowedPermissions, exceptPermissions, isAuthenticated ? user.permissions : []);
        let rolesCheck = this.checkRoles(allowedRoles, exceptRoles, isAuthenticated ? user.roles : []);
        let customPermissionCheck = this.props.customPermissionCheck ? this.props.customPermissionCheck(user) : true;

        let authenticationCheck = PermissionContainerImplementation.checkAuthentication(onlyForAuthenticated, onlyForUnauthenticated, auth.isAuthenticated);


        let newAccessible = permissionsCheck && rolesCheck && authenticationCheck && customPermissionCheck;

        if (this.state.accessible !== newAccessible) {
            this.setState({
                accessible: newAccessible,
            })
        }
    }


    render() {
        const {children, alternativeComponent} = this.props;
        const {accessible} = this.state;
        if (accessible) {
            return children
        }

        return alternativeComponent
    }
}

function mapStateToProps({auth}) {


    return {auth: auth}

}


export default connect(mapStateToProps, null)(PermissionContainerImplementation)
