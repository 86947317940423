import React from "react";
import { logoutRequest, loginSuccess } from "./actions";
import AuthorizationService from "./AuthorizationService";
import { connect } from "react-redux";

class UserInfoSyncer extends React.Component {

    componentDidMount() {
        const authorizationService = new AuthorizationService();
        authorizationService.authorize(this.props.accessToken).then(user => {
            this.props.loginSuccess(user);
        }).catch(error => {
            this.props.logoutRequest();
        });
    }

    render() {
        return null;
    }
}

function mapStateToProps({auth}) {

    const { accessToken } = auth;

    return { accessToken };

}
export default connect(mapStateToProps, {
    loginSuccess,
    logoutRequest,
})(UserInfoSyncer)